import React from 'react';
import locationHOC from '../../locationProvider/locationHOC';
import EmployeesSelect from '../../project/EmployeesSelect';
import UserSelectByCustomer from '../../project/UserSelectByCustomer';
import CustomersSelect from '../../project/CustomersSelect';
import RequestStatesSelect from '../../project/RequestStatesSelect';
import ColumnFilterLayout from '../../project/ColumnFilterLayout';
import DateRangePicker from '../../general/DateRangePicker';
import InputText from '../../general/InputText';
import {getQueryParam, createSetQueryParamValue, navigateToParametrized, getQueryParamNumber} from '../../../lib/url';
import {getInputOnChangeEventValue} from '../../../lib/project';
import {QUERY_PARAMS} from '../../../constants/navigation';
import {Location} from '../../../constants/propTypesDefinitions';
import {TRADE} from '../../../constants/trade';
import {RIGHTS} from '../../../constants/Rights';
import {Button, Radio} from 'antd';
import {Trans, t} from '@lingui/macro';
import InvoiceClassSelect from '../../project/InvoiceClassSelect';

/**
 * @dusan
 */

class InvoiceParams extends React.PureComponent {
    static propTypes = {
        location: Location.isRequired,
    };

    clearAllParams = () => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.INVOICE_REF]: undefined,
            [QUERY_PARAMS.INVOICE_CLASS]: undefined,
            [QUERY_PARAMS.INVOICE_ID_CUSTOMER]: undefined,
            [QUERY_PARAMS.INVOICE_ID_USER]: undefined,
            [QUERY_PARAMS.INVOICE_ID_SALESMAN]: undefined,
            [QUERY_PARAMS.INVOICE_AT]: undefined,
            [QUERY_PARAMS.INVOICE_TRANSFERED_AT]: undefined,
            [QUERY_PARAMS.INVOICE_PAYMENT_DUE]: undefined,
            [QUERY_PARAMS.INVOICE_IS_PAID]: undefined,
            [QUERY_PARAMS.INVOICE_IS_OVERDUE]: undefined,
            [QUERY_PARAMS.INVOICE_ORDER_BY]: undefined,
            [QUERY_PARAMS.INVOICE_SCROLL]: undefined,
            [QUERY_PARAMS.INVOICE_LIMIT]: process.env.DEFAULT_TABLE_MIN_RESULT_LIMIT,
        });
    };

    render() {
        const {location} = this.props;
        const dealerRef = getQueryParam(location, QUERY_PARAMS.INVOICE_REF);
        const invClass = getQueryParam(location, QUERY_PARAMS.INVOICE_CLASS);
        const customerId = getQueryParamNumber(location, QUERY_PARAMS.INVOICE_ID_CUSTOMER);
        const userId = getQueryParamNumber(location, QUERY_PARAMS.INVOICE_ID_USER);
        const salesmanId = getQueryParamNumber(location, QUERY_PARAMS.INVOICE_ID_SALESMAN);
        const invoicedAt = getQueryParam(location, QUERY_PARAMS.INVOICE_AT);
        const transferedAt = getQueryParam(location, QUERY_PARAMS.INVOICE_TRANSFERED_AT);
        const paymentDue = getQueryParam(location, QUERY_PARAMS.INVOICE_PAYMENT_DUE);
        const isPaid = getQueryParamNumber(location, QUERY_PARAMS.INVOICE_IS_PAID);
        const isOverdue = getQueryParamNumber(location, QUERY_PARAMS.INVOICE_IS_OVERDUE);
        
        const hasFilter = dealerRef != null || invClass != null  || userId != null || customerId != null || salesmanId != null
            || invoicedAt != null || transferedAt != null || paymentDue != null || isPaid != null || isOverdue != null;
        
        return <ColumnFilterLayout
            className="p-1"
            filters={[
                [
                    {
                        label: <Trans>Typ: </Trans>,
                        selector: <InvoiceClassSelect
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.INVOICE_CLASS)}
                            value={invClass}
                            allowClear={true}
                        />
                    },
                    {
                        label: <Trans>Číslo faktúry: </Trans>,
                        selector: <InputText
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.INVOICE_REF)}
                            value={dealerRef}
                        />
                    },
                    {
                        label: <Trans>Uhradené:</Trans>,
                        selector: <Radio.Group
                            onChange={(e) => {
                                const value = getInputOnChangeEventValue(e);
                                createSetQueryParamValue(location, QUERY_PARAMS.INVOICE_IS_PAID)(value);
                            }}
                            value={isPaid}
                        >
                            <Radio.Button value={1}><Trans>Áno</Trans></Radio.Button>
                            <Radio.Button value={0}><Trans>Nie</Trans></Radio.Button>
                            <Radio.Button value={undefined}><Trans>Všetky</Trans></Radio.Button>
                        </Radio.Group>
                    },
                    {
                        label: <Trans>Po splatnosti:</Trans>,
                        selector: <Radio.Group
                            onChange={(e) => {
                                const value = getInputOnChangeEventValue(e);
                                createSetQueryParamValue(location, QUERY_PARAMS.INVOICE_IS_OVERDUE)(value);
                            }}
                            value={isOverdue}
                        >
                            <Radio.Button value={1}><Trans>Áno</Trans></Radio.Button>
                            <Radio.Button value={0}><Trans>Nie</Trans></Radio.Button>
                            <Radio.Button value={undefined}><Trans>Všetky</Trans></Radio.Button>
                        </Radio.Group>
                    }
                ],
                [
                    {
                        label: <Trans>Organizácia: </Trans>,
                        selector: <CustomersSelect
                            className="full-size-width"
                            value={customerId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.INVOICE_ID_CUSTOMER)}
                            allowClear={true}
                        />,
                        rightsFrom: RIGHTS.MARKETING
                    },
                    {
                        label: <Trans>Kontakt: </Trans>,
                        selector: <UserSelectByCustomer
                            className="full-size-width"
                            value={userId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.INVOICE_ID_USER)}
                            allowClear={true}
                            customerId={customerId}
                        />,
                        rightsFrom: RIGHTS.MARKETING,
                    },
                    {
                        label: <Trans>Zodp. obchodník: </Trans>,
                        selector: <EmployeesSelect
                            className="full-size-width"
                            value={salesmanId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.INVOICE_ID_SALESMAN)}
                            allowClear={true}
                        />,
                        rightsFrom: RIGHTS.MARKETING,
                    }
                ],
                [
                    {
                        label: <Trans>Dátum vystavenia: </Trans>,
                        selector: <DateRangePicker
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.INVOICE_AT)}
                            value={invoicedAt}
                        />,
                    },
                    {
                        label: <Trans>Zdaniteľné plnenie: </Trans>,
                        selector: <DateRangePicker
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.INVOICE_TRANSFERED_AT)}
                            value={transferedAt}
                        />,
                    },
                    {
                        label: <Trans>Dátum splatnosti: </Trans>,
                        selector: <DateRangePicker
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.INVOICE_PAYMENT_DUE)}
                            value={paymentDue}
                        />,
                    },
                    {
                        label: null,
                        selector: <Button
                            type={hasFilter ? "danger" : null}
                            onClick={this.clearAllParams}
                            className="full-size-width"
                        >
                            <Trans>Vyčistiť všetky filtre</Trans>
                        </Button>,
                    }
                ]
            ]}
        />;
    }

}

export default locationHOC(InvoiceParams);