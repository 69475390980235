import React from 'react';
import PropTypes from 'prop-types';
import Helmet from '../project/Helmet';
import withDataHOC from '../dataProvider/withDataHOC';
import TableWithFiltersLayout from '../project/TableWithFiltersLayout';
import {GLOBAL_DATA} from '../../constants/globalData';
import {Invoice} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import { QUERY_PARAMS } from '../../constants/navigation';
import InvoiceParams from './invoices/InvoiceParams';
import InvoiceTable from './invoices/InvoiceTable';

/**
 * @dusan
 */

class InvoicesPage extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
        [GLOBAL_DATA.INVOICES]: PropTypes.arrayOf(Invoice.isRequired).isRequired,
    };

    render() {
        const {[GLOBAL_DATA.INVOICES]: invoices, [GLOBAL_DATA.RELOAD_DATA]: reload} = this.props;
        return <React.Fragment>
            <Helmet
                title={t`Faktúry`}
            />
            <TableWithFiltersLayout
                title={<Trans>Faktúry</Trans>}
                params={
                    <div>
                        <InvoiceParams/>
                    </div>
                }
                table={
                    <InvoiceTable
                        reload={() => reload([GLOBAL_DATA.INVOICES])}
                        invoices={invoices}
                    />
                }
                scrollParam={QUERY_PARAMS.INVOICE_SCROLL}
            />
        </React.Fragment>;
    }

}

export default withDataHOC([GLOBAL_DATA.INVOICES, GLOBAL_DATA.RELOAD_DATA])(InvoicesPage);