import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../dataProvider/withDataHOC';
import Select from '../general/Select';
import {GLOBAL_DATA} from '../../constants/globalData';

/**
 * @dusan
 */

class InvoiceClassSelect extends React.PureComponent {
    static propTypes = {
        value: PropTypes.string,
    };

    render() {
        const { [GLOBAL_DATA.INVOICE_CLASSES]: classes, ...props } = this.props;
        return <Select
            {...props}
            options={classes.map(c => {
                return {
                    label: c.name,
                    value: c.id,
                }
            })}
        />;
    }
}

export default withDataHOC([GLOBAL_DATA.INVOICE_CLASSES])(InvoiceClassSelect);