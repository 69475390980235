import React from 'react';
import PropTypes from 'prop-types';
import Select from '../general/Select';
import withDataHOC from '../dataProvider/withDataHOC';
import {Trans, t} from '@lingui/macro';
import {GLOBAL_DATA} from '../../constants/globalData';
import {RequestState} from '../../constants/propTypesDefinitions';
/**
 * @fero
 */

class RequestStatesSelect extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.REQUEST_STATES]: PropTypes.arrayOf(RequestState.isRequired).isRequired,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        onChange: PropTypes.func.isRequired,
    };

    render() {
        const {[GLOBAL_DATA.REQUEST_STATES]: states, ...props} = this.props;
        return <Select
            {...props}
            options={states.map(state => {
                return {
                    label: state.name,
                    value: state.id,
                }
            })}
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.REQUEST_STATES])(RequestStatesSelect);