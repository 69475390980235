import React from 'react';
import PropTypes from 'prop-types';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import locationHOC from '../../locationProvider/locationHOC';
import Link from '../../navigation/Link';
import Table from '../../general/Table';
import Tile from '../../general/Tile';
import TileAttribute from '../../general/TileAttribute';
import DateTime from '../../general/DateTime';
import {navigateToParametrized, getQueryParam, downloadPdf} from '../../../lib/url';
import {numberOrNull} from '../../../lib/number';
import {getInvoiceBackgroundStyle} from '../../../lib/project';
import {ROUTES, QUERY_PARAMS} from '../../../constants/navigation';
import {RIGHTS} from '../../../constants/Rights';
import {Invoice, InvoiceDetailLine} from '../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import withSessionHOC from '../../sessionProvider/withSessionHOC';
import CustomerLink from '../../general/CustomerLink';
import UserLink from '../../general/UserLink';
import withDataHOC from '../../dataProvider/withDataHOC';
import { GLOBAL_DATA } from '../../../constants/globalData';
import { Button } from 'antd';
import { createFetchInvoiceDetails } from '../../../backend/apiCalls';
import { formatPrice, formatProductName, formatQuantity, formatUnitPrice } from '../../../lib/formatting';
import Date from '../../general/Date';
import { SESSION_ATTRIBUTES } from '../../sessionProvider/SessionProvider';
import SimpleTable from '../../general/SimpleTable';

/**
 * @fero
 */

class InvoiceTable extends React.PureComponent {
    static propTypes = {
        invoices: PropTypes.arrayOf(Invoice.isRequired).isRequired,
        reload: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
    };

    setOrdering = (newOrder) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.INVOICE_ORDER_BY]: newOrder,
            [QUERY_PARAMS.INVOICE_SCROLL]: 0,
        });
    };

    setLimit = (newLimit) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.INVOICE_LIMIT]: newLimit,
        });
    };

    render() {
        const {location, reload, invoices, rights} = this.props;
        const orderBy = getQueryParam(location, QUERY_PARAMS.INVOICE_ORDER_BY);
        const currentLimit = numberOrNull(getQueryParam(location, QUERY_PARAMS.INVOICE_LIMIT));
    
        return <Table
            className={rights >= RIGHTS.MARKETING ? 'invoice-table-dealer' : 'invoice-table-customer'}
            BodyRow={InvoiceTableRowWrapped}
            BodyTile={InvoiceTile}
            data={invoices}
            limit={currentLimit}
            pageSize={!Number.isNaN(Number(process.env.DEFAULT_TABLE_PAGE_SIZE)) ? Number(process.env.DEFAULT_TABLE_PAGE_SIZE) : 20}
            changeLimit={this.setLimit}
            orderBy={orderBy}
            changeOrder={this.setOrdering}
            reload={reload}
            noTBody={true}
            colDefs={[
                {
                    class: 'invoice-table-col-arrow'
                },
                {
                    headerCell: <Trans>Č. faktúry</Trans>,
                    orderCol: 'dealer_reference',
                    class: 'invoice-table-col-id',
                },
                {
                    headerCell: <Trans>Typ</Trans>,
                    orderCol: 'class_name',
                    class: 'invoice-table-col-class'
                },
                {
                    headerCell: <Trans>Organizácia</Trans>,
                    orderCol: 'customer',
                    class: 'invoice-table-col-customer',
                    rightsFrom: RIGHTS.MARKETING
                },
                {
                    headerCell: <Trans>Kontakt</Trans>,
                    orderCol: 'user',
                    class: 'invoice-table-col-user',
                    rightsFrom: RIGHTS.MARKETING
                },
                {
                    headerCell: <Trans>Základ dane</Trans>,
                    orderCol: 'sum_netto',
                    class: 'invoice-table-col-sum',
                },
                {
                    headerCell: <Trans>Spolu s DPH</Trans>,
                    orderCol: 'sum_brutto',
                    class: 'invoice-table-col-sum',
                },
                {
                    headerCell: <Trans>Saldo</Trans>,
                    orderCol: 'saldo',
                    class: 'invoice-table-col-sum',
                },
                {
                    headerCell: <Trans>Zdaniteľné plnenie</Trans>,
                    orderCol: 'transfered_at',
                    class: 'invoice-table-col-transfered-at',
                },
                {
                    headerCell: <Trans>Dátum vystavenia</Trans>,
                    orderCol: 'invoiced_at',
                    class: 'invoice-table-col-invoiced-at',
                },
                {
                    headerCell: <Trans>Dátum a čas odoslania</Trans>,
                    orderCol: 'sent_at',
                    class: 'invoice-table-col-sent-at',
                },
                {
                    headerCell: <Trans>Dátum splatnosti</Trans>,
                    orderCol: 'payment_due',
                    class: 'invoice-table-col-payment-due',
                },
                {
                    headerCell: <Trans>Po splatnosti</Trans>,
                    orderCol: 'overdue',
                    class: 'invoice-table-col-overdue',
                },
                {
                    headerCell: <Trans>Poznámka</Trans>,
                    class: 'invoice-table-col-notices',
                }
            ]}
        />;
    }

}


class InvoiceTableRow extends React.PureComponent {
    static propTypes = {
        data: Invoice.isRequired,
        reload: PropTypes.func.isRequired,
        [SESSION_ATTRIBUTES.RIGHTS]: PropTypes.number.isRequired,
        [GLOBAL_DATA.FETCH_HANDLER]: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            opened: false,
            lines: [],
            orderBy: undefined,
        }
    }

    componentDidMount() {
        this.setState({
            opened: false,
            lines: [],
            orderBy: undefined,
        });
    }

    componentDidUpdate(prevProps) {
        const {data: prevData} = prevProps;
        const {id: prevInvoiceId} = prevData;
        const {data} = this.props;
        const {id: invoiceId} = data;

        if(invoiceId != prevInvoiceId) {
            this.setState({
                opened: false,
                lines: [],
                orderBy: undefined,
            });
        }
    }

    fetchLines = () => {
        const {data, [GLOBAL_DATA.FETCH_HANDLER]: fetchHandler} = this.props;
        const {id: invoiceId} = data;
        const {orderBy} = this.state;

        fetchHandler(
            createFetchInvoiceDetails(),
            {
                id: invoiceId,
                order: orderBy,
            },
            (result) => {
                const lines = result != null && result.lines != null ? result.lines : [];
                this.setState({ lines: lines });
            }
        );
    }

    toggleLines = () => {
        const {opened} = this.state;
        const newOpened = !opened;
        this.setState({opened: newOpened});
        if(newOpened)
            this.fetchLines();
    }

    changeOrder = (newOrder) => {
        this.setState({orderBy: newOrder});
    }

    render() {
        const {data, reload, [SESSION_ATTRIBUTES.RIGHTS]: rights} = this.props;
        const {opened, lines, orderBy} = this.state;
        return <tbody className={getInvoiceBackgroundStyle(data)}>
        <tr>
            <td rowSpan={opened ? 2 : 1}>
                <Button
                    size="small"
                    onClick={this.toggleLines}
                    icon={opened ? 'down' : 'right'}
                />
            </td>
            <td>
                <a
                    href={downloadPdf('invoice', data.id)}
                    className="px-2 text-dark font-weight-bold"
                    target="_blank"
                >
                    {data.dealer_reference}
                </a>
            </td>
            <td>{data.class_name}</td>
            <RightsWrapper from={RIGHTS.MARKETING}>
                <td>
                    <CustomerLink
                        customerId={data.id_customer}
                        customerName={data.customer}
                    />
                </td>
                <td>
                    <UserLink
                        userId={data.id_user}
                        userName={data.user}
                    />
                </td>
            </RightsWrapper>
            <td>{formatPrice(data.sum_netto, data.id_currency)}</td>
            <td>{formatPrice(data.sum_brutto, data.id_currency)}</td>
            <td>{formatPrice(data.saldo, data.id_currency)}</td>
            <td><Date dateString={data.transfered_at}/></td>
            <td><Date dateString={data.invoiced_at}/></td>
            <td><DateTime timeString={data.sent_at}/></td>
            <td><Date dateString={data.payment_due}/></td>
            <td>{formatQuantity(data.overdue, t`dní`)}</td>
            <td>
                <div className="text-overflow-ellipsis">{data.customer_notices}</div>
                <RightsWrapper from={RIGHTS.MARKETING}>
                    {data.dealer_notices != null ? <div className="font-italic text-overflow-ellipsis">{data.dealer_notices}</div> : null}
                </RightsWrapper>
            </td>
        </tr>
        {opened ? 
            <tr>
                <td colSpan={rights >= RIGHTS.MARKETING ? 13 : 11}>
                    <InvoiceLinesSimpleTable
                        lines={lines}
                    />
                </td>
            </tr> : 
            null
        }
        </tbody>;
    }
}

const InvoiceTableRowWrapped = withSessionHOC([SESSION_ATTRIBUTES.RIGHTS])(
    withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(InvoiceTableRow)
);

class InvoiceTile extends React.PureComponent {
    static propTypes = {
        data: Invoice.isRequired,
        reload: PropTypes.func.isRequired,
    };

    render() {
        const {data, reload} = this.props;
        return <Tile className={'p-2 ' + getInvoiceBackgroundStyle(data)}>
            <div className="flex-row-dynamic-static">
                <div className="align-self-center">
                    <a
                        href={downloadPdf('invoice', data.id)}
                        className="px-2 text-dark font-weight-bold"
                        target="_blank"
                    >
                        {data.dealer_reference}
                    </a>
                    <RightsWrapper from={RIGHTS.MARKETING}>
                        <TileAttribute
                            title={<Trans>Organizácia</Trans>}
                            value={
                                <CustomerLink
                                    customerId={data.id_customer}
                                    customerName={data.customer}
                                />
                            }
                        />
                        <TileAttribute
                            title={<Trans>Kontakt</Trans>}
                            value={
                                <UserLink
                                    userId={data.id_user}
                                    userName={data.user}
                                />
                            }
                        />
                    </RightsWrapper>
                </div>
            </div>
            <TileAttribute
                title={<Trans>Základ dane</Trans>}
                value={formatPrice(data.sum_netto, data.id_currency)}
            />
            <TileAttribute
                title={<Trans>Spolu s DPH</Trans>}
                value={formatPrice(data.sum_brutto, data.id_currency)}
            />
            <TileAttribute
                title={<Trans>Saldo</Trans>}
                value={formatPrice(data.saldo, data.id_currency)}
            />
            <TileAttribute
                title={<Trans>Zdaniteľné plnenie</Trans>}
                value={<Date dateString={data.transfered_at}/>}
            />
            <TileAttribute
                title={<Trans>Dátum vystavenia</Trans>}
                value={<Date dateString={data.invoiced_at}/>}
            />
            <TileAttribute
                title={<Trans>Dátum a čas odoslania</Trans>}
                value={<DateTime timeString={data.sent_at}/>}
            />
            <TileAttribute
                title={<Trans>Dátum splatnosti</Trans>}
                value={<Date dateString={data.payment_due}/>}
            />
            <TileAttribute
                title={<Trans>Po splatnosti</Trans>}
                value={formatQuantity(data.overdue, t`dní`)}
            />
            <TileAttribute
                title={<Trans>Poznámka</Trans>}
                value={<div className="text-overflow-ellipsis">{data.customer_notices}</div>}
            />
            <RightsWrapper from={RIGHTS.MARKETING}>
                <TileAttribute
                    title={<Trans>Interná poznámka</Trans>}
                    value={<div className="text-overflow-ellipsis">{data.dealer_notices}</div>}
                />
            </RightsWrapper>
        </Tile>;
    }
}

class InvoiceLinesSimpleTable extends React.PureComponent {
    static propTypes = {
        lines: PropTypes.arrayOf(InvoiceDetailLine).isRequired,
    };

    render() {
        const {lines} = this.props;
        return <SimpleTable
            className="simple-table-lines"
            data={lines}
            colDefs={[
                {
                    dataKeys: ['id_product', 'designation', 'manufacturer'],
                    render: (props) => { 
                        return <Link
                            className="text-dark"
                            to={ROUTES.PRODUCT_DETAILS}
                            queryParams={{[QUERY_PARAMS.ID_PRODUCT]: props.id_product}}
                            title={<Trans>Zobraziť detail produktu</Trans>}
                        >
                            {formatProductName(props.designation, props.manufacturer)}
                        </Link>
                    },
                    class: 'invoice-lines-table-col-designation'
                },
                {
                    dataKeys: ['id_order', 'order_reference'],
                    render: (props) => { 
                        return <Link
                            className="text-dark"
                            to={ROUTES.ORDER_DETAILS}
                            queryParams={{[QUERY_PARAMS.ID_ORDER]: props.id_order}}
                            title={<Trans>Zobraziť detail objednávky</Trans>}
                        >
                            {props.order_reference}
                        </Link>
                    },
                    class: 'invoice-lines-table-col-order'
                },
                {
                    dataKeys: ['package_quantity', 'package_type'],
                    render: (props) => { return formatQuantity(props.package_quantity, props.package_type || 'ks')},
                    class: 'invoice-lines-table-col-quantity text-right'
                },
                {
                    dataKeys: ['package_price', 'package_type', 'id_currency'],
                    render: (props) => { return formatUnitPrice(props.package_price, props.package_type, props.id_currency)},
                    class: 'invoice-lines-table-col-price text-right'
                },
            ]}
        />
    }
}

export default withSessionHOC([SESSION_ATTRIBUTES.RIGHTS])(locationHOC(InvoiceTable));
